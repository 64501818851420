<template>
  <App
    title="配车"
    left-arrow
  >
    <template slot="extra">
      <div class="order">
        <div class="order-detail">
          <div class="order-avator">
            <Icon
              name="description"
              class="icon"
            />
          </div>
          <div class="order-info">
            <h5 class="order-info-name">订单号</h5>
            <span class="order-info-mobile">{{orderInfo.orderNum}}</span>
          </div>
        </div>
        <div class="order-contact">
          <div>奥迪{{orderInfo.carSeries}}</div>
          <div>{{orderInfo.carType}}</div>
          <div>{{orderInfo.carColor}}</div>
        </div>
      </div>
      <SelectCell
        v-model="options.stockType"
        label="库存类型"
        :options="inventoryOptions"
        @on-select="onRefresh"
      />
    </template>
    <PullRefresh
      v-model="loading"
      @refresh="onRefresh"
    >
      <List
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :finished-text="total === 0 ? 'No data!' : 'No more items.!'"
        error-text="Loading failed. Click to retry"
        :immediate-check="false"
        @load="onLoad"
      >
        <Card
          v-for="e in inventoryList"
          :key="e.id"
        >
          <div class="content">
            <div class="title">{{e.carSeriesCn}} · {{e.carTypeCn}}</div>
            <div class="tags">
              <div
                v-for="ele in e.tags"
                :key="ele"
              >{{ele}}</div>
            </div>
            <div class="info">车龄：{{e.carDate}}</div>
            <div class="info">库龄：{{e.stockDate}}</div>
            <div class="info">外色：{{e.carColorName}}</div>
            <div class="info">VIN：{{e.vin}}
              <a
                v-clipboard:copy="e.vin"
                v-clipboard:success="onCopy"
                href="javascript:;"
                class="link-text"
              >复制</a>
            </div>
          </div>
          <div
            slot="footer"
            class="cu-footer"
          >
            <div style="line-height: 24px">{{e.carStockHouseName}}</div>
            <Button
              round
              type="info"
              size="mini"
              class="btn"
              @click="collocationcar(e)"
            >配车</Button>
          </div>
        </Card>
      </List>
    </PullRefresh>
  </App>
</template>
<script>
import Card from '_c/card/index'
import SelectCell from '_c/dynamicForm/selectCell'
import { inventoryList, preassign } from '@/api/chargequery'
import { Icon, Button, Toast, Dialog, List, PullRefresh } from 'vant'
import './index.less'

Toast.allowMultiple()

export default {
  name: 'configCar',
  components: {
    Icon,
    Card,
    Button,
    SelectCell,
    PullRefresh,
    List
  },
  data () {
    return {
      loading: false,
      finished: false,
      error: false,
      total: 0,
      page: {
        index: 1,
        size: 10
      },
      options: {
        stockType: '1'
      },
      inventoryOptions: Object.freeze([
        { label: '现车', value: '1' },
        { label: '可调拨车辆', value: '2' }
      ]),
      inventoryList: [],
      orderInfo: {}
    }
  },
  methods: {
    onLoad () {
      if (this.finished) return
      this.getInventoryList()
    },
    onRefresh () {
      this.finished = false
      this.page.index = 1
      this.getInventoryList(true)
    },
    getInventoryList (refresh = false) {
      this.loading = true
      const { page, params } = this.formatRequestParams()
      inventoryList(page, params).then(res => {
        if (res.success) {
          this.inventoryList = refresh ? res.data.pgdataList : [...res.data.pgdataList, ...this.inventoryList]
          this.total = res.data.page.totalResult
          this.finished = this.inventoryList.length === this.total
          if (!this.finished) {
            this.page.index++
          }
        } else {
          this.error = true
          Toast('数据加载失败!')
        }
      }).catch(() => {
        this.error = true
      }).finally(() => {
        this.loading = false
      })
    },
    formatRequestParams () {
      const page = {
        page: this.page.index,
        size: this.page.size
      }
      const params = {
        channel: this.$storage.get('channel'),
        stockType: this.options.stockType,
        carColorCode: this.orderInfo.carColorCode,
        carStatus: '2',
        vehicleCode: this.orderInfo.vehicleCode
      }
      if (params.stockType === '1') {
        params.queryDlrIdList = [{ queryDlrId: this.orderInfo.dcDlrId }]
      }
      return { page, params }
    },
    // 配车
    collocationcar (detail) {
      let toast
      Dialog.confirm({
        title: '配车确认',
        message: '是否确认配当前订单',
      }).then(() => {
        /* toast = Toast.loading({
          message: 'loading...',
          forbidClick: true,
          loadingType: 'spinner'
        }) */
        return preassign({
          VIN: detail.vin,
          channel: this.$storage.get('channel'),
          consultantId: this.orderInfo.consultantId,
          orderNum: this.orderInfo.orderNum
        })
      }).then(res => {
        if (res.success) {
          return Dialog.alert({
            title: '提示',
            message: '配车成功!'
          })
        }
        return Promise.reject(new Error(res.msg || '配车失败!'))
      }).then(() => {
        this.$router.go(-1)
      }).catch(err => {
        if (err.message) {
          Toast(err.message)
        }
      }).finally(() => {
        toast && toast.clear()
      })
    },
    onCopy () {
      Toast('复制成功!')
    }
  },
  mounted () {
    const pageParam = this.$route.query
    this.orderInfo = {
      orderNum: pageParam.orderNum,
      carSeries: pageParam.carSeries,
      carType: pageParam.carType,
      carColor: pageParam.carColor,
      carColorCode: pageParam.carColorCode,
      dcDlrId: pageParam.dcDlrId,
      consultantId: pageParam.consultantId,
      vehicleCode: pageParam.vehicleCode
    }
    this.onLoad()
  }
}
</script>
