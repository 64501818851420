<template>
  <div>
    <Filed v-bind="$attrs"
           :value="formatValueStr"
           :rules="rules"
           placeholder="Please select"
           input-align="right"
           is-link
           readonly
           @click="onClickCell" />
    <Popup v-model="show"
           round
           get-container="body"
           position="bottom">
      <Picker show-toolbar
              v-bind="attrs"
              :columns="options"
              value-key="label"
              @cancel="show = false"
              @confirm="onConfirm"
              @change="onChange">
        <template v-for="(n, slot) in $slots">
          <slot :name="slot" />
        </template>
      </Picker>
    </Popup>
  </div>
</template>
<script>
import { Picker, Popup, Toast } from 'vant'
import Filed from './field'

const formatter = (selected) => {
  if (Array.isArray(selected)) {
    return selected.map(item => item.label).join(' / ')
  } else {
    return selected.label
  }
}

export default {
  name: 'selectCell',
  components: {
    Filed,
    Picker,
    Popup
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    formatter: { // 格式化显示的数据
      type: Function,
      default: formatter
    },
    attrs: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      show: false,
      selected: { label: '', value: '' }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        const result = this.findPathByValue(this.options, value)
        if (result) {
          this.selected = result
        }
      }
    },
    options (value) {
      const result = this.findPathByValue(value, this.value)
      if (result) {
        this.selected = result
      }
    }
  },
  computed: {
    formatValueStr () {
      return this.formatter(this.selected)
    },
    defaultIndex () {
      return this.options.findIndex(item => item.value === this.value)
    }
  },
  methods: {
    onClickCell () {
      this.show = true
    },
    onConfirm (value) {
      if (value) {
        this.$emit('choose', value)
        if (Array.isArray(value)) {
          this.selected = this.findPathByLabels(this.options, value.slice())
          this.$emit('input', this.selected[this.selected.length - 1].value)
        } else {
          this.selected = value
          this.$emit('input', this.selected.value)
        }
        this.show = false
        this.$emit('on-select', this.selected)
      } else {
        Toast('No data')
        this.show = false
      }

    },
    onChange (picker, value, index) {
      this.$emit('change', picker, value, index)
    },
    findPathByLabels (treeData, labelArr) {
      const res = []
      const dfs = (treeData) => {
        if (!labelArr.length) return
        if (Array.isArray(treeData)) {
          for (const data of treeData) {
            const idx = labelArr.indexOf(data.label)
            if (idx > -1) {
              res.push(data)
              labelArr.splice(idx, 1)
            }
            dfs(data.children)
          }
        }
      }
      dfs(treeData)
      return res
    },
    findPathByValue (treeData, value) {
      let res = []
      let success = false
      const dfs = (treeData, stack) => {
        if (Array.isArray(treeData)) {
          for (const data of treeData) {
            if (success) return
            stack.push(data)
            if (data.value !== value) {
              dfs(data.children, stack)
              stack.pop()
            } else {
              res = [...stack]
              success = true
            }
          }
        }
      }
      dfs(treeData, [])
      return res
    }
  }
}
</script>